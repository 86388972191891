import React from 'react';
import { Root } from './style';

import { Link } from 'gatsby';

import vars from 'variables';

const EssentialInfo = () => {
  return (
    <Root>
      <ul>
        <li>
          <strong>Whitepaper</strong>
          <Link className="button blue" to={vars.pages.whitepaper}>
            View now
          </Link>
        </li>
        <li>
          <strong>Contract</strong>
          <a
            className="button blue"
            href={vars.links.contract}
            target="_blank"
            rel="noreferrer"
          >
            View now
          </a>
        </li>
        <li>
          <strong>Audit</strong>
          <a
            className="button blue"
            href={vars.links.audit}
            target="_blank"
            rel="noreferrer"
          >
            View now
          </a>
        </li>
      </ul>
    </Root>
  );
};

export default EssentialInfo;
